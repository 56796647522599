<template>
  <div class="wrapper text-left text-no-wrap">
    <div v-if="availableActions.length === 1">
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            @click.stop="execute(availableActions[0])"
          >{{availableActions[0].icon}}</v-icon>
        </template>
        {{$t(availableActions[0].name)}}
      </v-tooltip>
    </div>
    <responsive-menu
      v-else-if="availableActions.length > 1"
      :attach="attachEl"
      left
      :top="displayToTop"
      :z-index="this.displayZIndex || 2"
      v-model="isOpen"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="$emit('click', $event)"
        >
          <icon-tooltip
            :icon-name="'i.DotsVertical'"
            :tooltip-text="$t('t.Actions')"
          />
        </v-btn>
      </template>

      <action-list
        :actions="availableActions"
        @click="execute"
        ref="content"
      />
    </responsive-menu>
  </div>
</template>

<script>
import { Item } from '@/pages/search/controllers/item-list'
import Search from '@/pages/search/controllers'
import DisplayableInContainer from '@/pages/search/mixins/displayable-in-container'

export default {
  mixins: [DisplayableInContainer],
  components: {
    ActionList: () => import('./action-list'),
    ResponsiveMenu: () => import('@/pages/search/components/responsive-menu.vue'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  computed: {
    availableActions: {
      get () {
        return this.item.quickActions.filter(a => a.validateRules([this.item]))
      }
    }
  },
  methods: {
    execute (action) {
      action.executeQuick(
        this.item,
        isReverted => {
          this.search?.refreshResults()
          this.callback?.(isReverted)
        }
      )
    }
  },
  props: {
    type: [String, Boolean, Object, HTMLElement],
    callback: Function,
    item: Item,
    search: Search
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  position relative
</style>
